import { MyInfoType } from "./types";

export const GENDER_OPTIONS = [
  { id: "MALE", name: "Male" },
  { id: "FEMALE", name: "Female" },
];

export const INITIAL_MYINFO: MyInfoType = {
  address_line_1: "",
  address_line_2: "",
  alias_name: "",
  country: "",
  country_of_birth: "",
  date_of_birth: "",
  employer: "",
  employment_sector: "",
  full_name: "",
  gender: "",
  nationality: "",
  occupation: "",
  occupation_code: "",
  pass_expiry_date: "",
  pass_status: "",
  pass_status_last_updated: "",
  postal_code: "",
  race: "",
  residential_status: "",
  uinfin: "",
};
