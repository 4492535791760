import { ENDPOINTS } from "../endpoints";
import { CredentialsType, MYInfoPayload } from "../types";
import { request } from "../util/api";

export default class AuthAPI {
  static signIn = (payload: CredentialsType) =>
    request(ENDPOINTS.LOGIN, "POST", payload, true);

  static getSingpassUrl = () => request(ENDPOINTS.GET_SINGPASS_URL, "GET");

  static getMyInfoUser = (payload: MYInfoPayload) =>
    request(ENDPOINTS.GET_MY_INFO, "POST", payload);

  static submitMyInfo = (payload: any) =>
    request(ENDPOINTS.UPDATE_USER, "PUT", payload);
}
