import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

import Signin from "./screens/Signin.screen";
import Status from "./screens/Status.screen";
import ProtectedRoute from "./components/ProtectedRoute.component";

import "./App.scss";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/callback" element={<Status />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
