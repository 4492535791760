import Axios, { AxiosRequestConfig } from "axios";
import { ENDPOINTS } from "../endpoints";
import { STORAGE } from "../enums";
import { BASE_URL, REQUEST_HEADERS } from "../config";

Axios.interceptors.response.use(undefined, (err) => {
  return Promise.reject(err);
});

const getToken = () => {
  return localStorage.getItem(STORAGE.AUTH_TOKEN);
};

export const request = (
  endpoint: ENDPOINTS,
  method: string,
  requestData?: any,
  guest: boolean = false,
  customHeaders?: AxiosRequestConfig["headers"]
) =>
  new Promise((resolve, reject) => {
    const headers = {
      ...REQUEST_HEADERS,
      ...customHeaders,
    };

    const data = method === "GET" ? null : requestData;
    const params = method === "GET" ? requestData : null;

    if (!guest) {
      headers.Authorization = `Bearer ${getToken()}`;
    }

    const config = {
      method: method || "post",
      url: endpoint.replace("<BASE_URL>", BASE_URL),
      params,
      data,
      headers,
      timeout: 30000,
    };

    console.log(config);

    Axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((response) => {
        console.log("RESP ", response);
        reject(response.response);
      });
  });
