import React from "react";

import LOGO_WHITE from "../assets/logo-white.svg";

const Header = () => {
  return (
    <>
      <div className="header">
        <div className="content">
          <div className="logo">
            <a href="/" className="logo-link">
              <img src={LOGO_WHITE} alt="Your Canvas Logo" />
            </a>
          </div>
          <div className="links-right">
            <h1>eKYC</h1>
          </div>
        </div>
      </div>
      {/* <div className="hero">
        <img className="hero-bg" src={BG_ARCH} alt="arch" />
      </div> */}
    </>
  );
};

export default Header;
