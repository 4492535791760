import React, { ChangeEvent } from "react";
import { Button, Form, FormGroup, Label, Input, Col, Row } from "reactstrap";
import { MyInfoType } from "../common/types";
import { checkIfIdFin } from "../common/util";

type OwnProps = {
  myinfo: MyInfoType;
  myinfoPayload: MyInfoType;
  onChangeMyInfo: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  onToggleCheck: () => void;
  onSubmit: () => void;
};

const PersonalInfo = ({
  myinfo,
  onChangeMyInfo,
  isChecked,
  onToggleCheck,
  onSubmit,
  myinfoPayload,
}: OwnProps) => {
  const idType = myinfo?.uinfin
    ? checkIfIdFin(myinfo.uinfin)
      ? "FIN"
      : "NRIC"
    : "";

  const disableButton = () => {
    if (!isChecked) return true;
    if (myinfoPayload.full_name.length === 0) return true;
    if (myinfoPayload.uinfin.length === 0) return true;
    if (myinfoPayload.date_of_birth.length === 0) return true;
    if (myinfoPayload.gender.length === 0) return true;
    if (myinfoPayload.nationality.length === 0) return true;
    if (myinfoPayload.address_line_1.length === 0) return true;
    if (myinfoPayload.address_line_2.length === 0) return true;
    if (myinfoPayload.country.length === 0) return true;
    if (myinfoPayload.postal_code.length === 0) return true;

    return false;
  };

  return (
    <div className="personal-info">
      <Form>
        <h5>Please review and submit your details recieved from MyInfo</h5>
        <Form>
          <FormGroup>
            <Label for="full_name">Full Name</Label>
            <Input
              id="full_name"
              name="full_name"
              value={myinfoPayload?.full_name}
              disabled={myinfo?.full_name.length > 0}
              onChange={onChangeMyInfo}
            />
          </FormGroup>

          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="idtype">Identification No</Label>
                <Input
                  id="idtype"
                  name="idtype"
                  placeholder="ID TYPE"
                  value={idType}
                  disabled={myinfo?.uinfin.length > 0}
                  type="select"
                  readOnly
                >
                  <option>NRIC</option>
                  <option>FIN</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="uinfin">Number</Label>
                <Input
                  id="uinfin"
                  name="uinfin"
                  value={myinfoPayload?.uinfin}
                  disabled={myinfo?.uinfin.length > 0}
                  onChange={onChangeMyInfo}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="date-of-birth">Date of birth</Label>
            <Input
              id="date-of-birth"
              name="date_of_birth"
              type="datetime"
              value={myinfoPayload?.date_of_birth}
              disabled={myinfo?.date_of_birth.length > 0}
              onChange={onChangeMyInfo}
            />
          </FormGroup>

          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              id="gender"
              name="gender"
              value={myinfoPayload?.gender}
              disabled={myinfo?.gender.length > 0}
              onChange={onChangeMyInfo}
              type="select"
            >
              <option>FEMALE</option>
              <option>MALE</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="nationality">Nationality</Label>
            <Input
              id="nationality"
              name="nationality"
              value={myinfoPayload?.nationality}
              disabled={myinfo?.nationality.length > 0}
              onChange={onChangeMyInfo}
            />
          </FormGroup>

          <FormGroup>
            <Label for="address_line_1">Address</Label>
            <Input
              id="address_line_1"
              name="address_line_1"
              value={myinfoPayload?.address_line_1}
              disabled={myinfo?.address_line_1.length > 0}
              onChange={onChangeMyInfo}
            />
          </FormGroup>
          <FormGroup>
            <Label for="address_line_2">Address line 2</Label>
            <Input
              id="address_line_2"
              name="address_line_2"
              value={myinfoPayload?.address_line_2}
              disabled={myinfo?.address_line_2.length > 0}
              onChange={onChangeMyInfo}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="country">Country</Label>
                <Input
                  id="country"
                  name="country"
                  value={myinfoPayload?.country}
                  disabled={myinfo?.country.length > 0}
                  onChange={onChangeMyInfo}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="postal_code">Postal Code</Label>
                <Input
                  id="postal_code"
                  name="postal_code"
                  value={myinfoPayload?.postal_code}
                  disabled={myinfo?.postal_code.length > 0}
                  onChange={onChangeMyInfo}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <FormGroup check inline>
          <Input type="checkbox" checked={isChecked} onChange={onToggleCheck} />
          <Label check>
            By continuing, you accept our{" "}
            <a
              href="https://s3-ap-southeast-1.amazonaws.com/yourcanvas.co/Canvas_App_and_Card_Terms_and_Conditions.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use{" "}
            </a>
            and
            <a
              href="https://s3-ap-southeast-1.amazonaws.com/yourcanvas.co/CANVAS_PRIVACY_POLICY.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Privacy Policy
            </a>
          </Label>
        </FormGroup>
      </Form>
      <section>
        <Button
          color="primary"
          className="theme bg"
          onClick={onSubmit}
          disabled={disableButton()}
        >
          Submit
        </Button>
      </section>
    </div>
  );
};

export default PersonalInfo;
