import { ENDPOINTS } from "../endpoints";
import { STORAGE } from "../enums";
import { LogType } from "../types";
import { request } from "../util/api";

export default class LoggerAPI {
  static log = (payload: LogType) =>
    request(ENDPOINTS.LOGGER, "POST", {
      text:
        "```" +
        JSON.stringify({
          ...payload,
          userId: localStorage.getItem(STORAGE.USER_ID) || "",
          env: window.location.origin,
        }) +
        "```",
    });
}
