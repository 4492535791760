import moment from "moment";
import { MyInfoType } from "../types";
import { REKYC_STATUS } from "../enums";
import { countryCode } from "../config";

export const checkIfIdFin = (value: string): boolean => {
  const firstLetter = value.replace(/\s/g, "").toUpperCase().charAt(0);
  return firstLetter === "F" || firstLetter === "G" || firstLetter === "M";
};

export const normalizeMyInfo = (data: MyInfoType): MyInfoType => {
  const modified: MyInfoType = {
    address_line_1: data?.address_line_1 || "",
    address_line_2: data?.address_line_2 || "",
    alias_name: data?.alias_name || "",
    country: data?.country || "",
    country_of_birth: data?.country_of_birth || "",
    date_of_birth: data?.date_of_birth || "",
    employer: data?.employer || "",
    employment_sector: data?.employment_sector || "",
    full_name: data?.full_name || "",
    gender: data?.gender || "",
    nationality: data?.nationality || "",
    occupation: data?.occupation || "",
    occupation_code: data?.occupation_code || "",
    pass_expiry_date: data?.pass_expiry_date || "",
    pass_status: data?.pass_status || "",
    pass_status_last_updated: data?.pass_status_last_updated || "",
    postal_code: data?.postal_code || "",
    race: data?.race || "",
    residential_status: data?.residential_status || "",
    uinfin: data?.uinfin || "",
  };
  return modified;
};

export const normalizeMyInfoData = (
  {
    country,
    date_of_birth,
    gender,
    full_name,
    uinfin,
    postal_code,
    address_line_1,
    address_line_2,
    nationality,
  }: MyInfoType,
  state: string
) => {
  return {
    fullName: full_name,
    addressCountryCode: country,
    gender,
    nationality: nationality || country,
    idNumber: uinfin,
    idType: checkIfIdFin(uinfin) ? "FIN" : "NRIC",
    addressLine1: address_line_1,
    addressLine2: address_line_2,
    addressPostalCode: postal_code,
    dateOfBirth: moment(date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY"),
    mobileCountryCode: countryCode,
    reKycStatus: REKYC_STATUS.PENDING,
    state,
  };
};
