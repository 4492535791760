import React, { ChangeEvent, useState } from "react";

import Header from "../components/Header.component";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import Notice from "../components/Notice.component";
import AuthAPI from "../common/api/auth.api";
import { AuthType, CredentialsType } from "../common/types";
import { countryCode } from "../common/config";
import { STORAGE } from "../common/enums";
import LoggerAPI from "../common/api/logger.api";

enum SIGNIN_METHODS {
  MOBILE = "mobile",
  NRIC = "idNumber",
}

const Signin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<CredentialsType>({
    mobile: "",
    password: "",
  });
  const [signInMethod, setSignInMethod] = useState<SIGNIN_METHODS>(
    SIGNIN_METHODS.MOBILE
  );
  const [error, setError] = useState<string | null>(null);

  const onToggleSignInMethod = () => {
    const toggle =
      signInMethod === SIGNIN_METHODS.NRIC
        ? SIGNIN_METHODS.MOBILE
        : SIGNIN_METHODS.NRIC;
    setSignInMethod(toggle);
  };

  const onChangeCredentials = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onPressSignIn = () => {
    if (credentials.mobile.length === 0) {
      const type = signInMethod === SIGNIN_METHODS.MOBILE ? "Mobile" : "ID";
      return setError(`${type} is required!`);
    }

    if (credentials.password.length === 0) {
      return setError("Password is required!");
    }

    if (isNaN(Number(credentials.mobile))) {
      return setError("The mobile must be a number!");
    }

    setIsLoading(true);
    const payload: any = {
      loginType: signInMethod,
      password: credentials.password,
    };

    if (signInMethod === SIGNIN_METHODS.MOBILE) {
      payload.mobile = credentials.mobile;
      payload.mobileCountryCode = countryCode;
    } else {
      payload.idNumber = credentials.mobile;
    }

    AuthAPI.signIn(payload)
      .then((res: any) => {
        const data: AuthType = res?.data;
        localStorage.setItem(STORAGE.AUTH_TOKEN, data.accessToken);
        localStorage.setItem(STORAGE.USER_ID, data.userId);
        LoggerAPI.log({
          action: "USER LOGGED IN",
          status: 200,
        });

        AuthAPI.getSingpassUrl()
          .then((_data: any) => {
            const data: { redirectUrl: string } = _data?.data;
            window.location.href = data.redirectUrl;
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.data.message === "KYC_ALREADY_DONE") {
              setError("Your KYC is already submitted!");
            }
            LoggerAPI.log({
              action: "GET USER DETAILS",
              status: error.status,
              error: JSON.stringify(error?.data) || error,
            });
          });
      })
      .catch((error) => {
        setError(error?.data.message);
        setIsLoading(false);
        LoggerAPI.log({
          action: "USER LOGGED IN :  FAILED",
          status: error.status,
          error: JSON.stringify(error?.data) || error,
        });
      });
  };

  const isActivePhone = signInMethod === SIGNIN_METHODS.MOBILE;

  const renderSpinner = isLoading && <Spinner className="loading" size="sm" />;

  const renderSignInType =
    signInMethod === SIGNIN_METHODS.MOBILE ? (
      <>
        <Label for="phone">Phone</Label>
        <FormGroup row>
          <Label sm={2}>+65</Label>
          <Col sm={10}>
            <Input
              id="phone"
              name="mobile"
              type="text"
              onChange={onChangeCredentials}
              maxLength={8}
            />
          </Col>
        </FormGroup>
      </>
    ) : (
      <FormGroup>
        <Label>Identification No</Label>
        <Input
          id="phone"
          name="mobile"
          type="text"
          onChange={onChangeCredentials}
          maxLength={9}
        />
      </FormGroup>
    );

  return (
    <div className="screen-container">
      <Header />
      <div className="theme-card-container">
        <Card className="theme-card">
          <CardBody>
            <div className="sigin-in-options">
              <Button
                outline={!isActivePhone}
                color="primary"
                block
                onClick={onToggleSignInMethod}
              >
                Mobile
              </Button>
              <Button
                color="primary"
                outline={isActivePhone}
                block
                onClick={onToggleSignInMethod}
              >
                Identification No
              </Button>
            </div>
            <Form>
              {renderSignInType}

              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  onChange={onChangeCredentials}
                />
              </FormGroup>
            </Form>
            <Notice message={error} setError={setError} />
            <section>
              <Button
                color="primary"
                className="theme bg"
                disabled={isLoading}
                onClick={onPressSignIn}
              >
                {renderSpinner}
                Sign in
              </Button>
            </section>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Signin;
