export enum STORAGE {
  AUTH_TOKEN = "AUTH_TOKEN",
  USER_ID = "USER_ID",
}

export enum REKYC_STATUS {
  VERIFIED = "VERIFIED",
  NOT_VERIFIED = "NOT_VERIFIED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}
