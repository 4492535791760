import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";

type OwnProps = {
  color?: string;
  message?: string | null;
  setError: (message: string | null) => void;
};

const Notice = ({ color = "danger", message, setError }: OwnProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (message && message.length > 0) {
      setIsVisible(true);
      setTimeout(() => {
        toggle();
        setError(null);
      }, 5000);
    }
  }, [message, setError]);

  const toggle = () => setIsVisible(false);

  return (
    <Alert color={color} isOpen={isVisible} toggle={toggle}>
      {message}
    </Alert>
  );
};

export default Notice;
