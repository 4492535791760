import React, { useState, useEffect, ChangeEvent } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";

import Header from "../components/Header.component";
import PersonalInfo from "../components/PersonalInfo.component";
import { Card, CardBody, Spinner } from "reactstrap";
import AuthAPI from "../common/api/auth.api";
import { MyInfoType } from "../common/types";
import { normalizeMyInfo, normalizeMyInfoData } from "../common/util";
import { INITIAL_MYINFO } from "../common/constants";
import SUCCESS from "../assets/success.svg";
import FAIL from "../assets/failed.svg";
import LoggerAPI from "../common/api/logger.api";

enum KYC_STATUS {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  VERIFY_KYC = "VERIFY_KYC",
}

enum KYC_ERROR_STATUS {
  INCOMPLETE_USER_DETAILS = 403,
  ALREADY_SUBMITTED = 500,
}

const Status = () => {
  const query = new URLSearchParams(useLocation().search);
  const state = query.get("state") || "";
  const code = query.get("code") || "";
  const error = query.get("error") || null;

  const [kycStatus, setKycStatus] = useState<KYC_STATUS>(KYC_STATUS.SUCCESS);
  const [myinfo, setMyInfo] = useState<MyInfoType>(INITIAL_MYINFO);
  const [myinfoPayload, setMyInfoPayload] =
    useState<MyInfoType>(INITIAL_MYINFO);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [kycError, setKYCError] = useState<KYC_ERROR_STATUS | null>(null);

  const onChangeMyInfo = (e: ChangeEvent<HTMLInputElement>) => {
    setMyInfoPayload({ ...myinfo, [e.target.name]: e.target.value });
  };

  const onToggleCheck = () => setIsChecked(!isChecked);

  useEffect(() => {
    setKycStatus(KYC_STATUS.LOADING);
    if (error) {
      setKycStatus(KYC_STATUS.ERROR);
      LoggerAPI.log({
        status: 400,
        action: "Callbakc MyInfo: ERROR",
        state,
        code,
        error: JSON.stringify(error) || error,
      });
    } else {
      if (code?.length > 0 || state?.length > 0) {
        AuthAPI.getMyInfoUser({ code, state })
          .then((_user: any) => {
            const user: MyInfoType = normalizeMyInfo(_user.data);
            if (_user?.status === 404) {
              return window.location.reload();
            } else {
              setMyInfo(user);
              setMyInfoPayload(user);
              setKycStatus(KYC_STATUS.VERIFY_KYC);
              LoggerAPI.log({
                status: 200,
                action: "GET MyInfo Data: SUCCESS",
                state,
                code,
                data: JSON.stringify(user),
              });
            }
          })
          .catch((error) => {
            setKycStatus(KYC_STATUS.ERROR);
            LoggerAPI.log({
              status: error.data?.status || 100,
              action: "GET MyInfo Data: Failed",
              state,
              code,
              data: "",
              error: JSON.stringify(error?.data) || error,
            });
          });
      } else {
        setKycStatus(KYC_STATUS.ERROR);
        LoggerAPI.log({
          status: 400,
          action: "Callbakc MyInfo: ERROR",
          state,
          code,
          error: "Can't find state and code",
        });
      }
    }
  }, [code, state, error]);

  const onSubmit = () => {
    window.scrollTo(0, 0);
    setKycStatus(KYC_STATUS.LOADING);
    const payload = normalizeMyInfoData(myinfoPayload, state);
    AuthAPI.submitMyInfo(payload)
      .then(() => {
        setKycStatus(KYC_STATUS.SUCCESS);
        LoggerAPI.log({
          status: 200,
          action: "Submit MyInfo Data: SUCCESS",
          state,
          code,
          data: JSON.stringify(payload),
        });
      })
      .catch((res) => {
        setKYCError(res.data.statusCode);
        setKycStatus(KYC_STATUS.ERROR);
        LoggerAPI.log({
          status: res.data?.status || 100,
          action: "Submit MyInfo Data: Failed",
          state,
          code,
          data: JSON.stringify(payload),
          error: JSON.stringify(res?.data) || res,
        });
      });
  };

  const goBackToForm = () => setKycStatus(KYC_STATUS.VERIFY_KYC);

  const renderKycError = () => {
    if (kycError === KYC_ERROR_STATUS.ALREADY_SUBMITTED) {
      return <p>Your KYC is already submitted!</p>;
    } else if (kycError === KYC_ERROR_STATUS.INCOMPLETE_USER_DETAILS) {
      return (
        <>
          <p>Your KYC submission is incomplete. Please return to the form</p>
          <Button color="primary" className="theme bg" onClick={goBackToForm}>
            Submit
          </Button>
        </>
      );
    }

    return (
      <>
        <p>There has been an issue with your re-verifcation.</p>
        <p>Please contact customer services for more information.</p>
        <h6>+65 87261472</h6>
        <p>Email: support@go-bank.me</p>
      </>
    );
  };

  const renderBody = () => {
    if (kycStatus === KYC_STATUS.LOADING) {
      return (
        <section className="wait">
          <Spinner className="loading status" color="success" />
          <h5>Please wait...</h5>
        </section>
      );
    } else if (kycStatus === KYC_STATUS.ERROR) {
      return (
        <div className="status-container">
          <img src={FAIL} alt="status-check" className="status-icon" />
          <h4 style={{ color: "gray" }}>Verification Error</h4>
          {renderKycError()}
        </div>
      );
    } else if (kycStatus === KYC_STATUS.VERIFY_KYC) {
      return (
        <PersonalInfo
          myinfo={myinfo}
          onChangeMyInfo={onChangeMyInfo}
          isChecked={isChecked}
          onToggleCheck={onToggleCheck}
          onSubmit={onSubmit}
          myinfoPayload={myinfoPayload}
        />
      );
    } else {
      return (
        <div className="status-container">
          <img src={SUCCESS} alt="status-check" className="status-icon" />
          <h4 style={{ color: "#54b69e" }}>Success</h4>
          <p>Verification of identity successful.</p>
          <p>Please open the app.</p>
        </div>
      );
    }
  };

  return (
    <div className="screen-container">
      <Header />
      <div className="theme-card-container">
        <Card className="theme-card">
          <CardBody>{renderBody()}</CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Status;
